import React from 'react';
import Fade from 'react-reveal/Fade';
import GDSC from '../assets/gdsc.png';
import secondbind from '../assets/secondbind.png';
import znotes from '../assets/znotes.png';
import yorku from '../assets/yorku.png';
import lab2client from '../assets/lab2client.svg';

const Bio = () => {
    return (
        <div className="Bio">
            <div className="bio" id="bio">
                <div className="wrapper">
                    <Fade><p><p style={{fontWeight: "bold"}}>About me</p>I'm currently in the third year of my CS degree at York University. I am mostly exploring fields like Software Engineering and Machine Learning.</p></Fade>
                    <Fade><p><p style={{fontWeight: "bold"}}>Work experience</p><em>Software Engineer Intern</em> @ <a href="https://dayforce.com">Dayforce</a> (current)</p></Fade>
                    <p><em>Research Assistant</em> @ <a href="https://lassonde.yorku.ca/">Lassonde</a> (previous) <br/> <em>Software Engineer Intern</em> @ <a href="https://znotes.org">ZNotes</a> (previous)</p>
                    <Fade><p><p style={{fontWeight: "bold"}}>Fun fact</p>I love going to Hackathons and collecting free stuff!</p></Fade>
                    {/* <Fade>
                        <ul>
                            <li>
                                <strong>Experience</strong>
                                <p>Research Assistant @ <a href='https://pixel.lab.yorku.ca/'><img width={120} src="https://www.yorku.ca/lassonde/lab/data-science/wp-content/themes/york2020-lassonde-child/images/Lassonde_REV.png" /></a> <br /> <span>Aug 2023 - Present</span></p>
                                <p>Software Engineer @ <a href="https://lab2client.com/"><img width={120} src={lab2client} /></a> <br /> <span>May 2023 - Present</span></p>
                                <p>AI Engineer @ <a href="https://www.radicalx.co/"><img width={120} src="https://uploads-ssl.webflow.com/64e02bd14ada8a57fa327cbe/64e5947b9605183a5dadb10d_Frame%2010637%20(6).png" /></a> <br /> <span>Oct 2023 - Jan 2024</span></p>
                                <p>Tech Lead @ <a href="https://gdsc.community.dev/york-university/"><img width={120} src={GDSC} /></a> <br /> <span>Jan 2023 - May 2023</span></p>
                                <p>Developer Intern @ <a href="https://secondbind.com"><img width={120} src={secondbind} /></a> <br /> <span>Feb 2023 - Apr 2023</span></p>
                                <p>Software Engineer @ <a href="https://znotes.org"><img width={150} src={znotes} /></a> <br /> <span>Sep 2021 - Sep 2022</span></p>
                            </li>
                            <li className='accomplishments'>
                                <strong>Achievements</strong>
                                <p>York Engineering Competition 2023 <br /> <span>1st place</span></p>
                                <p>Hack the North 10 (Canada's biggest hackathon) <br /> <span>ETHGlobal challenge winner</span></p>
                                <p>Canadian Engineering Competition 2023 <br /> <span>Top 8 nationally</span></p>
                                <p>Ontario Engineering Competition 2023 <br /> <span>2nd place</span></p>
                                <p>York Engineering Competition 2022 <br /> <span>1st place</span></p>
                            </li>
                            <li>
                                <strong>Education</strong>
                                <p>BSc Computer Science @ <img width={100} src={yorku} /></p>
                            </li>
                        </ul>
                    </Fade> */}
                </div>
            </div>
        </div>
    );
};

export default Bio;