import React from 'react';

const Footer = () => {
    return (
        <div className="Footer" id="footer">
            <div className="thanks">
                <img src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678134-sign-check-512.png" width="50"/>
                <br/>
                <h1>Like my work? Awesome!</h1>
                <p>Email me at <strong className="mono">idrak888@my.yorku.ca</strong></p>
            </div>
            <div className="links">
                <div className="row">
                    <div className="col-xs">
                        <a href="https://www.linkedin.com/in/idrak-islam/"><img src="https://cdn-icons-png.flaticon.com/512/174/174857.png"/></a>
                    </div>
                    <div className="col-xs">
                        <a href="https://github.com/idrak888"><img src="https://cdn1.iconfinder.com/data/icons/logotypes/32/github-512.png"/></a>
                    </div>
                    <div className="col-xs">
                        <a href="https://medium.com/@idrakislam"><img src="https://cdn4.iconfinder.com/data/icons/social-media-circle-7/512/Medium_circle-512.png"/></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;