import React from 'react';
import pupil7preview from '../assets/pupil7preview.png';
import chemdocspreview from '../assets/chemdocspreview.png';

import blockify from '../assets/blockify.png';
import celadonai from '../assets/celadonai.png';
import lab2client from '../assets/lab2client.png';
import statsy from '../assets/statsy.png'
import tickerblue from '../assets/tickerblue 2.png';
import vitalwave from '../assets/vitalwave.png';
import therapyai from '../assets/therapyai.png';
import maple from '../assets/maple.png';
import nexustimer from '../assets/nexustimer.gif';
import gpt from '../assets/radicalx.jpeg';
import remix from '../assets/remix.png';

const Work = () => {
    return (
        <div className="Work">
            <div className="work" id="work">
                <div className="wrapper">
                    <div className="row">
                        <a href='https://digitize-eth.vercel.app/'><div className="col-sm blockify">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img className='preview' src={blockify} />
                                </div>
                                <div className='col-md-7'>
                                    <h3>Bl0ckify - Hack the North</h3>
                                    <ul>
                                        <li>
                                            A decentralized NFT marketplace project that runs on the Ethereum Blockchain. This app allows users to mint physical collectibles and trade them as Non-fungible tokens.
                                        </li>
                                        <br />
                                        <li>
                                            <div className='skill-pill'>TypeScript</div>
                                            <div className='skill-pill'>React.js</div>
                                            <div className='skill-pill'>Next.js</div>
                                            <div className='skill-pill'>Thirdweb</div>
                                            <div className='skill-pill'>CockroachDB</div>
                                            <div className='skill-pill'>Sepolia ETH</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div></a>
                    </div>
                    <div className="row">
                        <a href='https://www.celadonai.app/'><div className="col-sm celadonai">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img className='preview' src={celadonai} />
                                </div>
                                <div className='col-md-7'>
                                    <h3>Celadon AI - Productivity chatbot</h3>
                                    <ul>
                                        <li>
                                            This app uses NLP techniques to improve productivity by letting users train and generate custom Chatbots from documents.
                                        </li>
                                        <br />
                                        <li>
                                            <div className='skill-pill'>React.js</div>
                                            <div className='skill-pill'>Python</div>
                                            <div className='skill-pill'>Flask</div>
                                            <div className='skill-pill'>PyTorch</div>
                                            <div className='skill-pill'>OpenAI API</div>
                                            <div className='skill-pill'>Google Cloud</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div></a>
                    </div>
                    <div className="row">
                        <a href='https://lab2client.com/'><div className="col-sm lab2client">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img className='preview' src={lab2client} />
                                </div>
                                <div className='col-md-7'>
                                    <h3>Lab2Client - Research Startup</h3>
                                    <ul>
                                        <li>
                                            An innovative web platform that connects the broader research and innovation community with under-utilized research facilities.
                                        </li>
                                        <br />
                                        <li>
                                            <div className='skill-pill'>React.js</div>
                                            <div className='skill-pill'>Bootstrap</div>
                                            <div className='skill-pill'>Node.js</div>
                                            <div className='skill-pill'>Express</div>
                                            <div className='skill-pill'>Firebase</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div></a>
                    </div>
                    <div className="row">
                        <a href='https://devpost.com/software/greenplates?ref_content=my-projects-tab&ref_feature=my_projects'><div className="col-sm greenplates">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img className='preview' src="https://d112y698adiu2z.cloudfront.net/photos/production/software_photos/002/716/937/datas/original.png" />
                                </div>
                                <div className='col-md-7'>
                                    <h3>GreenPlates - DeltaHacks</h3>
                                    <ul>
                                        <li>
                                            Your Culinary Co-Pilot for Effortless Health. Unleash the Power of AI and React as GreenPlates transforms your fridge into a treasure trove of healthy recipes.
                                        </li>
                                        <br />
                                        <li>
                                            <div className='skill-pill'>Node.js</div>
                                            <div className='skill-pill'>OpenAI</div>
                                            <div className='skill-pill'>React.js</div>
                                            <div className='skill-pill'>Firebase</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div></a>
                    </div>
                    <div className="row">
                        <a href='https://www.nexustimer.pro/'><div className="col-sm nexustimer">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img className='preview' src={nexustimer} />
                                </div>
                                <div className='col-md-7'>
                                    <h3>NexusTimer - Open Source</h3>
                                    <ul>
                                        <li>
                                            NexusTimer is a powerful unopinionated, minimalist training tool for SpeedCubing. Multi-platform support and made for professional use.
                                        </li>
                                        <br />
                                        <li>
                                            <div className='skill-pill'>TypeScript</div>
                                            <div className='skill-pill'>Next.js</div>
                                            <div className='skill-pill'>Tailwind CSS</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div></a>
                    </div>
                    <div className="row">
                        <a href='https://www.pupil7.com/'><div className="col-sm pupil7">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img className='preview' src={pupil7preview} />
                                </div>
                                <div className='col-md-7'>
                                    <h3>Pupil7 - EdTech Startup</h3>
                                    <ul>
                                        <li>
                                            An exam resources website that I co-founded and developed. Some features include: Android mobile app, Built-in MCQ solver, visual analytics dashboard, ect.
                                        </li>
                                        <br />
                                        <li>
                                            <div className='skill-pill'>React.js</div>
                                            <div className='skill-pill'>React Native</div>
                                            <div className='skill-pill'>Bootstrap</div>
                                            <div className='skill-pill'>Node.js</div>
                                            <div className='skill-pill'>Express</div>
                                            <div className='skill-pill'>MongoDB</div>
                                            <div className='skill-pill'>Firebase</div>
                                            <div className='skill-pill'>Puppeteer</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div></a>
                    </div>
                    <div className="row">
                        <a href='https://github.com/idrak888/ui-bot'><div className="col-sm uibot">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img className='preview' src={gpt} />
                                </div>
                                <div className='col-md-7'>
                                    <h3>Radical AI - Design Assistance Tool</h3>
                                    <ul>
                                        <li>
                                            Collaborated with a team and engineered an UI/UX design assistance tool with OpenAI and Google Vision as a part of my internship at RadicalX.
                                        </li>
                                        <br />
                                        <li>
                                            <div className='skill-pill'>Python</div>
                                            <div className='skill-pill'>Flask</div>
                                            <div className='skill-pill'>OpenAI</div>
                                            <div className='skill-pill'>GPT-4</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div></a>
                    </div>
                    <div className="row">
                        <a href='https://github.com/idrak888/vitalwave'><div className="col-sm vitalwave">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img className='preview' src={vitalwave} />
                                </div>
                                <div className='col-md-7'>
                                    <h3>VitalWave - Ontario Engineering Competition</h3>
                                    <ul>
                                        <li>
                                            Engineered an application backed by an algorithm aimed at reducing wait times in the Canadian
                                            healthcare system based on users' needs and conditions. The project won second place provincially.
                                        </li>
                                        <br />
                                        <li>
                                            <div className='skill-pill'>React.js</div>
                                            <div className='skill-pill'>Next.js</div>
                                            <div className='skill-pill'>Python</div>
                                            <div className='skill-pill'>Google Maps API</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div></a>
                    </div>
                    <div className="row">
                        <a href='https://devpost.com/software/remix-mobile-app-for-discovering-smoothie-recipes'><div className="col-sm">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img className='preview' src={remix} />
                                </div>
                                <div className='col-md-7'>
                                    <h3>Remix - McHacks</h3>
                                    <ul>
                                        <li>
                                            Enhance your smoothie-making experience with Remix. Discover unique smoothie recipes matched to the ingredients that you have on hand at home.
                                        </li>
                                        <br />
                                        <li>
                                            <div className='skill-pill'>React Native</div>
                                            <div className='skill-pill'>MongoDB</div>
                                            <div className='skill-pill'>Python</div>
                                            <div className='skill-pill'>Flask</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div></a>
                    </div>
                    {/* <div className="row">
                        <a href='https://statsy.vercel.app/'><div className="col-sm statsy">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img className='preview' src={statsy} />
                                </div>
                                <div className='col-md-7'>
                                    <h3>Statsy - Web-Scraper</h3>
                                    <ul>
                                        <li>
                                            A Wikipedia web scraper bot made with Puppeteer that scrapes Economic data. The frontend is built with React and it has dark mode so its really cool.
                                        </li>
                                        <br />
                                        <li>
                                            <div className='skill-pill'>React.js</div>
                                            <div className='skill-pill'>Node.js</div>
                                            <div className='skill-pill'>Puppeteer</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div></a>
                    </div> */}
                    <div className="row">
                        <a href='https://play.google.com/store/apps/details?id=com.chemdocs&hl=en_CA&gl=US&pli=1'><div className="col-sm chemdocs">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img className='preview' src={chemdocspreview} />
                                </div>
                                <div className='col-md-7'>
                                    <h3>ChemDocs - Chemistry App</h3>
                                    <ul>
                                        <li>
                                            Personal mobile-app project, showcasing my full-stack capabilities. I designed the UI/UX, developed an API and mobile app using React Native.
                                        </li>
                                        <br />
                                        <li>
                                            <div className='skill-pill'>React Native</div>
                                            <div className='skill-pill'>Node.js</div>
                                            <div className='skill-pill'>Express</div>
                                            <div className='skill-pill'>Firebase</div>
                                            <div className='skill-pill'>MongoDB</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div></a>
                    </div>
                    <div className="row">
                        <a href='https://devpost.com/software/therapyai-roxh25'><div className="col-sm therapyai">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img className='preview' src={therapyai} />
                                </div>
                                <div className='col-md-7'>
                                    <h3>TherapyAI - UofTHacks</h3>
                                    <ul>
                                        <li>
                                            TherapyAI is an online chatbot that gives people instant access to different medical needs using NLP techniques.
                                        </li>
                                        <br />
                                        <li>
                                            <div className='skill-pill'>React.js</div>
                                            <div className='skill-pill'>Node.js</div>
                                            <div className='skill-pill'>Express</div>
                                            <div className='skill-pill'>Cohere API</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div></a>
                    </div>
                    <div className="row">
                        <a href='https://github.com/KaranpreetRaja/academic-management-system'><div className="col-sm maple">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img className='preview' src={maple} />
                                </div>
                                <div className='col-md-7'>
                                    <h3>Maple AMS - York Engineering Competition</h3>
                                    <ul>
                                        <li>
                                            An extensive Academic Management System for universities. Won first place in the York Engineering Competition (Programming Category) 2023.
                                        </li>
                                        <br />
                                        <li>
                                            <div className='skill-pill'>React.js</div>
                                            <div className='skill-pill'>Node.js</div>
                                            <div className='skill-pill'>Firebase</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div></a>
                    </div>
                    <div className="row">
                        <a href='https://tickeroid.web.app/analysis'><div className="col-sm tickerblue">
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img className='preview' src={tickerblue} />
                                </div>
                                <div className='col-md-7'>
                                    <h3>Tickerblue - Financial App</h3>
                                    <ul>
                                        <li>
                                            A stock analysis app for investors. The app generates reports based on sentiments, technical and fundamental data. It also summarizes the latest news about the company.
                                        </li>
                                        <br />
                                        <li>
                                            <div className='skill-pill'>React.js</div>
                                            <div className='skill-pill'>React Native</div>
                                            <div className='skill-pill'>Yahoo Finance API</div>
                                            <div className='skill-pill'>Alphavantange</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Work;