import React from 'react';

const Menu = () => {
    return (
        <div className="Menu">
            <a href='#bio' style={{color: "white"}}><div className='link-wrapper'>
                <div className="menu-link link1">
                    <h3>About me</h3>
                </div>
            </div></a>
            <a href='#work' style={{color: "white"}}><div className='link-wrapper'>
                <div className="menu-link link2">
                    <h3>Projects</h3>
                </div>
            </div></a>
            <a href='#footer' style={{color: "white"}}><div className='link-wrapper'>
                <div className="menu-link link3">
                    <h3>Get in touch</h3>
                </div>
            </div></a>
        </div>
    );
};

export default Menu;