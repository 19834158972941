import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HorizontalBar } from 'react-chartjs-2';
import { Fade } from 'react-reveal';
import $ from 'jquery';

import '../styles/AnimatedBorder.css';

import Header from '../components/Header';
import Menu from '../components/Menu';
import Bio from '../components/Bio';
import Work from '../components/Work';
import Footer from '../components/Footer';

class Home extends Component {
    state = {
        showSkills: false,
        barData: {
            labels: ['JS', 'Python', 'Java', 'Ruby'],
            datasets: [{
                label: 'Skill level',
                maxBarThickness: 40,
                data: [100, 90, 80, 80],
                borderWidth: 1,
                backgroundColor: [
                    '#7FFFD4',
                    '#78EFC7',
                    '#70DEB9',
                    '#66CAA9'
                ]
            }]
        },
        barOptions: {
            legend: {
                labels: {
                    fontColor: 'aquamarine',
                    fontSize: 18,
                    fontFamily: 'roboto mono'
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        fontColor: 'aquamarine',
                        fontFamily: 'roboto mono'
                    }
                }],
                xAxes: [{
                    ticks: {
                        beginAtZero: true,
                        fontColor: 'aquamarine',
                        fontFamily: 'roboto mono'
                    }
                }]
            }
        }
    }
    componentDidMount() {
        let fetchRes = fetch("https://rps-online-io.herokuapp.com/");
        fetchRes.then(res => res.json()).then(d => {
            console.log(d)
        }).catch(e => {
            console.log(e);
        });

        setTimeout(() => {
            this.setState({showSkills: true});
        }, 500);
        $(document).ready(() => {
            $('.Home').hide();
            $('.Home').fadeIn(500);
            const bio = $('#bio');
            const work = $('#work');
            const footer = $('#footer');

            $(".link1").click(function (){
                $('html, body').animate({
                    scrollTop: bio.offset().top
                });
            });
            $(".link2").click(function (){
                $('html, body').animate({
                    scrollTop: work.offset().top
                });
            });
            $(".link3").click(function (){
                $('html, body').animate({
                    scrollTop: footer.offset().top
                });
            });
            $("#topBtn").click(() => {
                $('html, body').animate({
                    scrollTop: 0
                });
            });
        });
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    handleScroll(event) {
        var mybutton = document.getElementById('topBtn');

        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            mybutton.style.display = "block";
        } else {
            mybutton.style.display = "none";
        }
    }
    render() {
        return (
            <div className="Home">
                <Header/>
                {/* <div className="loader">
                    <p>brewing</p>
                </div> */}
                <div className="belowHeader">
                    <div className='overlay'></div>
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-sm-7 box">
                                <h1 className='greeting'>{`> hello there`}</h1>
                                <p style={{textWrap: "balance"}}>I'm a <span className="mono">Computer Science</span> student from Toronto, have a look around!</p>
                            </div>   
                            <div className="col-sm-5">
                                <Menu/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <Bio/>
                    <div className="animationContainer">
                        <div className="line"></div>
                        <img className="animation" src="https://media3.giphy.com/media/4MjuRP1dIfy0g/giphy.gif" width="150"/>
                        <div className="line"></div>
                    </div>
                    {/* <div className="skills" id="skills">
                        <h3>Expertise</h3>
                        <br/>
                        {
                            this.state.showSkills ? 
                            <Fade>
                                <div className="container">
                                    <HorizontalBar 
                                        data={this.state.barData}
                                        options={this.state.barOptions}
                                    />
                                </div>
                            </Fade>
                            : null
                        }
                        <br/>
                        <div className="icons">
                            <img src={react} width="50"/>
                            <img src="https://cdn.worldvectorlogo.com/logos/nodejs-icon.svg" width="40"/>
                            <img src={bootstrap} width="70"/>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className="animationContainer">
                        <div className="line"></div>
                        <img className="animation" src="https://media2.giphy.com/media/3o6gE51uXycrKW6D84/giphy.gif" width="150"/>
                        <div className="line"></div>
                    </div> */}
                    <Work/>
                    <div style={{height: 100}}></div>
                    <div className="animationContainer">
                        <div className="line"></div>
                        <img className="animation" src="https://media2.giphy.com/media/l378lTvlx7OWemXtu/giphy.gif" width="150"/>
                        <div className="line"></div>
                    </div>
                    <div style={{height: 100}}></div>
                    <h4 style={{textAlign: "center", color: "white", marginTop: 50, marginBottom: 50}}>Made with ❤ by Idrak | Copyright © {(new Date().getFullYear())}</h4>
                    <div style={{height: 30}}></div>
                    <Footer/>
                    <button onClick={this.backToTop} id="topBtn" title="Go to top">
                        <img src="https://d33wubrfki0l68.cloudfront.net/55795601c33b029eadd4944a9bb5583adc76c289/2a917/img/giphy5.gif" width="30"/> 
                    </button>
                </div>
            </div>
        );
    }
}

export default Home;